import React from 'react';
import styled from 'styled-components';
import Metadata from '../components/Metadata';
import Section from '../components/Section';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-top: 1rem;

    @media (min-width: 576px) {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
`;

const StyledAddress = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  text-align: center;

  span {
    margin-bottom: 1rem;
    display: block;
  }
`;

function Impressum() {
  return (
    <>
      <Metadata title='Impressum' />
      <StyledContainer>
        <h1>Impressum</h1>
        <Section>
          <StyledAddress>
            <span>Sabrina Liebich</span>
            <span>Lernen mit Rückenwind - Autismustherapie</span>
            <span>Tonhallenstraße 19</span>
            <span>47051 Duisburg</span>
            <span>Telefon: +49 (0)157 38930161</span>
            <span>
              <a href='mailto:info@lernen-rueckenwind.de'>
                info@lernen-rueckenwind.de
              </a>
            </span>
          </StyledAddress>
        </Section>

        <Section>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h3 style={{ marginBottom: '1rem', textAlign: 'center' }}>
              Verantwortlich für Redaktionelle Inhalte
            </h3>
            <p>Sabrina Liebich</p>
          </div>
        </Section>

        <Section>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h3 style={{ marginBottom: '1rem' }}>Webentwicklung & Design</h3>
            <StyledAddress>
              <span>Maximilian Neitzel</span>
              <a
                href='https://www.maxneitzel.de'
                target='_blank'
                rel='noreferrer'
              >
                maxneitzel.de
              </a>
            </StyledAddress>
          </div>
        </Section>

        <Section>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h3 style={{ marginBottom: '1rem' }}>Bildnachweise</h3>
            <p>Bild von Sabrina Liebich: </p>
            <p>Lara Burr-Evans</p>
            <a
              href='http://www.laraburrevans.com'
              target='_blank'
              rel='noreferrer'
            >
              www.laraburrevans.com
            </a>
            <br />
            <p>Bilder der Räumlichkeiten:</p>
            <p>Nico Dombrowsky</p>
            <a
              href='https://www.nicodombrowsky.com/'
              target='_blank'
              rel='noreferrer'
            >
              www.nicodombrowsky.com
            </a>
          </div>
        </Section>
      </StyledContainer>
    </>
  );
}

export default Impressum;
